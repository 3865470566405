import './trusted.css';
const Trusted = () => {
    const images = [
        '/code-makers.png',
        '/Dalet.png',
        '/Entrix.png',
        '/Ethos.png',
        '/Hide-Seek.png',
        '/Magellan.png',
        '/Pallas.png',
        '/Pelion.png',
        '/vertical.png',
        '/Tion.png',
        '/Telefonica.png',
        '/Synergix.png',
      ];
  return (
    <section id='trusted' className='bg-black'>
      <div className='flex flex-col justify-center items-center pb-10'>
        <h2 className='font-opensans text-2xl font-semibold text-center'>
          Trusted by 1,000+ companies around the world
        </h2>
        <div className="marquee-container md:w-[60%] w-90% pt-5">
      <div className="marquee">
        {images.map((src, index) => (
          <img key={index} src={process.env.PUBLIC_URL + src} alt={`group${index + 1}`} className="marquee-item" />
        ))}
        {images.map((src, index) => (
          <img key={index + images.length} src={process.env.PUBLIC_URL + src} alt={`group${index + 1}`} className="marquee-item" />
        ))}
      </div>
    </div>
      </div>
    </section>
  );
};
export default Trusted;
