import React from 'react';
import SvgIcons from './svg';

const FAQs = () => {
  const [faqs, setFaqs] = React.useState([
    {
      title: 'What services does Unitech Agency provide?',
      answer:
        'We offer a range of services including custom software development, web development, mobile app development, IT consulting, and cloud solutions. Our goal is to provide tailored technology solutions to meet your business needs.',
      show: false,
    },
    {
      title: 'How can I get a quote for my project?',
      answer:
        'You can get a quote by contacting us through our Contact Form or by emailing us at contact@unitechagency.com. Provide details about your project and our team will get back to you with a tailored proposal.',
      show: false,
    },
    {
      title: 'What industries do you specialize in?',
      answer:
        'We have experience working with a variety of industries including finance, healthcare, e-commerce, education, and more. Our versatile team is equipped to handle projects across different sectors.',
      show: false,
    },
    {
      title: 'Do you offer support and maintenance services?',
      answer:
        'Yes, we offer comprehensive support and maintenance services to ensure your technology solutions remain up-to-date and perform optimally. This includes regular updates, troubleshooting, and enhancements.',
      show: false,
    },
    {
      title: 'How long does it take to complete a project?',
      answer:
        'The timeline for project completion varies depending on the scope and complexity of the project. Once we understand your requirements, we can provide a detailed timeline and keep you updated throughout the development process.',
      show: false,
    },
  ]);
  const hanldeHide = (idx) => {
    let temp = [...faqs];
    temp[idx].show = !temp[idx].show;
    setFaqs(temp);
  };
  return (
    // <section className="faqs">
    //   <h2>Frequently Asked Questions</h2>
    //   <div className="faq">
    //     <h3>Q1: What services does Unitech Agency provide?</h3>
    //     <p>A1: We offer a range of services including custom software development, web development, mobile app development, IT consulting, and cloud solutions. Our goal is to provide tailored technology solutions to meet your business needs.</p>
    //   </div>
    //   <div className="faq">
    //     <h3>Q2: How can I get a quote for my project?</h3>
    //     <p>A2: You can get a quote by contacting us through our Contact Form or by emailing us at contact@unitechagency.com. Provide details about your project and our team will get back to you with a tailored proposal.</p>
    //   </div>
    //   <div className="faq">
    //     <h3>Q3: What industries do you specialize in?</h3>
    //     <p>A3: We have experience working with a variety of industries including finance, healthcare, e-commerce, education, and more. Our versatile team is equipped to handle projects across different sectors.</p>
    //   </div>
    //   <div className="faq">
    //     <h3>Q4: Do you offer support and maintenance services?</h3>
    //     <p>A4: Yes, we offer comprehensive support and maintenance services to ensure your technology solutions remain up-to-date and perform optimally. This includes regular updates, troubleshooting, and enhancements.</p>
    //   </div>
    //   <div className="faq">
    //     <h3>Q5: How long does it take to complete a project?</h3>
    //     <p>A5: The timeline for project completion varies depending on the scope and complexity of the project. Once we understand your requirements, we can provide a detailed timeline and keep you updated throughout the development process.</p>
    //   </div>
    // </section>
    <section id='FAQs' class='bg-black pb-[200px]'>
      <h2 className='font-opensans text-2xl md:text-4xl font-bold pt-9  pb-10 '>
        Frequently Asked Questions
      </h2>
      <div className='w-full flex flex-col items-center mt-10 gap-y-5'>
        {faqs?.map((ele, idx) => {
          return (
            <div
              className='border border-[#ffffff] md:w-[700px] w-[90%] pt-5 pb-5 rounded-lg cp'
              onClick={() => {
                hanldeHide(idx);
              }}
            >
              <div className='flex justify-between pl-5 pr-5 gap-10 '>
                <h2 className={`text-left font-opensans text-lg ${ ele.show && 'text-[#0040FF] transition duration-200 ease-in-out'}` }>{ele.title}</h2>
                <div>
                  {ele.show ? <SvgIcons.ArrowUp /> : <SvgIcons.ArrowDown />}
                </div>
              </div>
              {ele.show && <p className='text-left font-montserrat pl-5 pr-5 pt-9 '>{ele.answer}</p>}
            </div>
          );
        })}
      </div>
     
    </section>
  );
};

export default FAQs;
