import React from 'react';
import Header from './Header';

const HeroSection = () => {
  return (
    // <section className="hero-section">
    //   <video autoPlay muted loop className="bg-video">
    //     <source src={process.env.PUBLIC_URL + '/night_sky.mp4'} type="video/mp4" />
    //     Your browser does not support the video tag.
    //   </video>
    //   <div className="content">
    //     <h1>Welcome to Unitech Agency</h1>
    //     <h2>Your Partner in Cutting-Edge Technology Solutions</h2>
    //     <button>Contact Us</button>
    //   </div>
    // </section>

    // <section className='h-screen p-0 bg-black'>
    //    <video
    //     autoPlay
    //     loop
    //     muted
    //     src={process.env.PUBLIC_URL + '/video-header-home.mp4'}
    //     className="absolute inset-0 w-full h-full object-cover z-[-1]"
    //   ></video>
    //   <Header />
    //   <section className=' mt-[230px] z-10'>
    //     <h1 className='font-poppins md:text-6xl md:font-semibold font-medium text-4xl '>Welcome to Unitech Agency</h1>
    //     <h2 className='font-montserrat mt-4 text-[#0040FF] md:text-lg text-base'>Your Partner in Cutting-Edge Technology Solutions</h2>
    //     <button className='font-opensans hover:text-[#0040FF] hover:bg-[#ffffff] transition duration-300 ease-in-out bg-[#0040FF] text-[#ffffff] font-semibold pt-3 pb-3 pl-5 pr-5 mt-6 rounded-3xl'>Contact Us</button>
    //   </section>
    //   {/* <div>
       
    //   </div> */}
    // </section>
    <section id='Home' className='bg-Video h-screen p-0 bg-black'>
    {/* <video
      autoPlay
      loop
      muted
      src={process.env.PUBLIC_URL + '/video-header-home.mp4'}
      className="absolute inset-0 w-full h-full object-cover "
    ></video> */}
    <Header />
    <section className='mt-[230px]  text-center'>
      <h1 className='font-poppins hidden md:block md:text-6xl md:font-semibold font-medium text-4xl text-white leading-8'>
      The world is moving fast.<br/> We help you move faster.
      </h1>
      <h1 className='font-poppins block md:hidden md:text-6xl md:font-semibold font-medium text-4xl text-white '>
      The world is moving fast.We help you move faster.
      </h1>
      <h2 className='font-montserrat mt-4 text-white md:text-lg text-base'>
        Your Partner in Cutting-Edge Technology Solutions
      </h2>
      <button className='font-opensans hover:text-[#0040FF] hover:bg-[#ffffff] transition duration-300 ease-in-out bg-[#0040FF] text-[#ffffff] font-semibold py-4 px-6 mt-6 rounded-3xl'>
        Speak To Uni Team
      </button>
    </section>
  </section>
  );
};

export default HeroSection;
