import React from 'react';

const AboutUs = () => {
  return (
    <div id='About' className='bg-black flex justify-center items-center z-10 relative pb-10'>
    <section className="bg-[#0040FF] w-[80%] pt-[40px] rounded-[40px] mt-[-50px]">
      <h2 className='font-opensans text-2xl font-bold md:text-2xl text-bold p-2 pl-10 pr-10 bg-white w-fit rounded-3xl ml-auto mr-auto text-[#0040FF]'>About Us</h2>
      <p className='font-montserrat md:text-xl pt-3 md:text-center md:p-2'>Uni Tech Agency is a leading provider of innovative technology solutions. Our mission is to empower businesses with advanced tech tools and services, helping them achieve their goals efficiently and effectively. With a team of expert developers and tech enthusiasts, we deliver customized solutions that meet the unique needs of our clients.</p>
    </section>
    </div>
  );
};

export default AboutUs;
