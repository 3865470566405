const WhyChoose = () => {
  return (
    <section className="bg-black">
      <div className="md:w-[70%] w-[90%] bg-[#192346] p-5 ml-auto mr-auto rounded-[19px] flex md:flex-row flex-col">
        <div className="md:w-[50%] flex items-center">
            <img src={process.env.PUBLIC_URL + '/whyChoose.png'} />
        </div>
        <div className="md:w-[50%] flex flex-col justify-center mt-10 md:mt-0">
            <h2 className="font-bold md:text-3xl text-2xl font-opensans pb-5">Why Choose UNI Team</h2>
            <p className="font-montserrat md:text-xl">We are not just any agency; we aim to be an extension of your team. With open communication guaranteed 40 hours a week, we ensure that we're always there for you, ready to guide, support, and grow your business.</p>
        </div>
      </div>
    </section>
  );
};
export default WhyChoose;
