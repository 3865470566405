import React from 'react';

const Process = () => {
  return (
    <section className="bg-black pb-10">
      <h2 className='font-opensans text-2xl md:text-4xl font-bold pt-9  pb-5 '>Our Process</h2>
      <div className='min-w-[80%] max-w-[1000px] ml-auto mr-auto md:block hidden'>
        <img src={process.env.PUBLIC_URL + '/process2.png'} alt="Discovery and Consultation" />
      </div>
      <div className='w-[90%] ml-auto mr-auto md:hidden block'>
        <div className=' w-[100%] h-[250px] bg-[#ffcd4c] border-dashed border-[4px] border-white  mt-4 rounded-[24px] flex items-center justify-center flex-col'>
          <h2 className='text-black font-semibold text-xl w-fit'>Step 1</h2>
          <p className='text-black font-montserrat'>Discovery and Consultation.</p>
        </div>
        <div className='bg-[#003eff]  text-white w-[100%] h-[250px] border-dashed border-[4px] border-white bg-contain mt-4 rounded-[24px] flex items-center justify-center flex-col'>
        <h2 className='text-black font-semibold text-xl'>Step 2</h2>
          <p className='text-black font-montserrat' >Planning and Strategy.</p>
        </div>
        <div className=' w-[100%] h-[250px] bg-contain border-dashed border-[4px] border-white  bg-[#ffcd4c] mt-4 rounded-[24px] flex items-center justify-center flex-col'>
        <h2 className='text-black font-semibold text-xl'>Step 3</h2>
          <p className='text-black font-montserrat'>Design and Development.</p>
        </div>
        <div className='bg-[#003eff] text-white border-dashed border-[4px] border-white w-[100%] h-[250px] bg-contain mt-4 rounded-[24px] flex items-center justify-center flex-col'>
        <h2 className='text-black font-semibold text-xl'>Step 4</h2>
          <p className='text-black font-montserrat'>Testing and Quality Assurance.</p>
        </div>
        <div className=' w-[100%] h-[250px] bg-contain border-dashed border-[4px] border-white  bg-[#ffcd4c] mt-4 rounded-[24px] flex items-center justify-center flex-col'>
        <h2 className='text-black font-semibold text-xl'>Step 5</h2>
          <p className='text-black font-montserrat'>Deployment and Support</p>
        </div>
      </div>
      {/* <ol>
        <li>Discovery and Consultation</li>
        <li>Planning and Strategy</li>
        <li>Design and Development</li>
        <li>Testing and Quality Assurance</li>
        <li>Deployment and Support</li>
      </ol> */}
    </section>
  );
};

export default Process;
