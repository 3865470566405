import React from 'react';
import SvgIcons from './svg';
const Services = () => {
  const [services, setServices] = React.useState([
    {
      heading: 'Custom Software Development',
      description: 'Tailored software solutions to meet your business needs.',
      icon: <SvgIcons.Software />,
    },
    {
      heading: 'Web Development',
      description: 'Building responsive and high-performing websites.',
      icon: <SvgIcons.WebIcon />,
    },
    {
      heading: 'Mobile App Development',
      description:
        'Creating user-friendly mobile applications for iOS and Android.',
      icon: <SvgIcons.Mobile />,
    },
    {
      heading: 'IT Consulting',
      description:
        'Professional advice to enhance your IT infrastructure and strategy.',
      icon: <SvgIcons.Consulting />,
    },
    {
      heading: 'Cloud Solutions',
      description:
        'Scalable and secure cloud services to support your business operations.',
      icon: <SvgIcons.Cloud />,
    },
  ]);
  return (
    <div id='Services' className={`bg-Image pt-10 pb-10 bg-cover`}>
       
      <h2 className='font-opensans text-2xl md:text-4xl font-bold pt-9  pb-10 '>
        Our Services
      </h2>
      <div className='w-full flex justify-center flex-wrap gap-5'>
        {services.map((ele, idx) => {
          return (
            <div className='rounded-[20px] border-[#ffffff] md:w-[40%] w-[90%] bg-[#000000]  border h-[150px]  flex items-center p-4 gap-4'>
              <div className='p-2 rounded-[16px]'>
                {ele.icon}
              </div>
              <div>
                <h3 className=' font-semibold text-white font-opensans text-xl text-left '>
                  {ele.heading}
                </h3>
                <p className='text-left font-montserrat font-base text-white'>
                  {' '}
                  {ele.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {/* <ul>
        <li>Custom Software Development: Tailored software solutions to meet your business needs.</li>
        <li>Web Development: Building responsive and high-performing websites.</li>
        <li>Mobile App Development: Creating user-friendly mobile applications for iOS and Android.</li>
        <li>IT Consulting: Professional advice to enhance your IT infrastructure and strategy.</li>
        <li>Cloud Solutions: Scalable and secure cloud services to support your business operations.</li>
      </ul> */}
    </div>
  );
};

export default Services;
