import React from 'react';
import Carousel from './carousalsTeam';

const Team = () => {
  return (
    <section id='Team' className='bg-black'>
      <h2 className='font-opensans text-2xl md:text-4xl font-bold pt-9  pb-5 '>
        Meet Our Team
      </h2>
      <p className='p-4 font-montserrat  text-white ml-auto mr-auto mb-[100px] w-fit rounded-[15px]'>
        Our team of experts is dedicated to delivering the best technology
        solutions for your business.
      </p>
      {/* <div className="team-member">
        <h3>Sean T.</h3>
        <p>CEO & Lead Developer</p>
      </div>
      <div className="team-member">
        <h3>Alex M.</h3>
        <p>Senior Software Engineer</p>
      </div>
      <div className="team-member">
        <h3>Lisa R.</h3>
        <p>Project Manager</p>
      </div>
      <div className="team-member">
        <h3>Tom K.</h3>
        <p>UX/UI Designer</p>
      </div> */}
      <div className='md:flex justify-around flex-wrap hidden'>
        <div className='md:w-[22%] w-[90%]'>
          <div>
            <img className="rounded-[26px]" src={process.env.PUBLIC_URL + '/jeol.png'} />
          </div>
          <div className='text-white pt-3 text-left font-montserrat'>
            Jack A. - CEO & Lead Developer
          </div>
        </div>
        <div className='md:w-[22%] w-[90%]'>
          <div>
            <img className="rounded-[26px]" src={process.env.PUBLIC_URL + '/jack.png'} />
          </div>
          <div className='text-white pt-3 text-left font-montserrat'>
            Alex M. - Senior Software Engineer
          </div>
        </div>
        <div className='md:w-[22%] w-[90%]'>
          <div>
            <img className="rounded-[26px]" src={process.env.PUBLIC_URL + '/lisa.png'} />
          </div>
          <div className='text-white pt-3 text-left font-montserrat'>
            Lisa R. - Project Manager
          </div>
        </div>
        <div className='md:w-[22%] w-[90%]'>
          <div>
            <img className="rounded-[26px]" src={process.env.PUBLIC_URL + '/ben.png'} />
          </div>
          <div className='text-white pt-3 text-left font-montserrat'>
            Tom K. - UX/UI Designer
          </div>
        </div>
      </div>
      <div className='md:hidden block'>
        <Carousel />
      </div>
    </section>
  );
};

export default Team;
